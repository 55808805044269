<template>
  <main>
    <div id="mentions_legales">
      <h2>CONDITIONS GÉNÉRALES</h2>
      <p>Les présentes conditions générales régissent l’utilisation de ce site https://projet-emancipation.clement-torchiat.fr/.<br>
        Ce site appartient et est géré par Alain Lamboux-Durand.<br>
        En utilisant ce site, vous indiquez que vous avez lu et compris les conditions d’utilisation et que vous acceptez de les respecter en tout temps.<br>
        Type de site : blog
      </p>
      <h3>Propriété intéllectuelle</h3>
      <p>Tout contenu publié et mis à disposition sur ce site est la propriété de Alain Lamboux-Durand et de ses créateurs. Cela comprend, mais n’est pas limité aux images, textes, logos, documents, fichiers téléchargeables et tout ce qui contribue à la composition de ce site.</p>
      <h3>Utilisation acceptable</h3>
      <p>En tant qu’utilisateur, vous acceptez d’utiliser notre site légalement et de ne pas utiliser ce site pour des fins illicites, à savoir :<br>
        -Violer les droits des autres utilisateurs du site<br>
        -Violer les droits de propriété intellectuelle des propriétaires du site ou de tout tiers au site<br>
        -Afficher tout matériel qui peut être jugé inapproprié ou offensant<br>
        Si nous estimons que vous utilisez ce site illégalement ou d’une manière qui viole les conditions d’utilisation acceptable ci-dessus, nous nous réservons le droit de limiter, suspendre ou résilier votre accès à ce site. Nous nous réservons également le droit de prendre toutes les mesures juridiques nécessaires pour vous empêcher d’accéder à notre site.
      </p>
      <h3>Contributions d'utilisateur</h3>
      <p>Les utilisateurs peuvent publier les informations suivantes sur notre site :<br>
        -Photos<br>
        -Vidéos<br>
        En affichant publiquement sur notre site, vous acceptez de ne pas agir illégalement ou violer les conditions d’utilisation acceptable énumérées dans ce document.
      </p>
      <h3>Limitation de responsabilité</h3>
      <p>Alain Lamboux-Durand ou l’un de ses employés sera tenu responsable de tout problème découlant de ce site. Néanmoins, Alain Lamboux-Durand et ses employés ne seront pas tenus responsables de tout problème découlant de toute utilisation irrégulière de ce site.</p>
      <h3>Indemnité</h3>
      <p>En tant qu’utilisateur, vous indemnisez par les présentes Alain Lamboux-Durand de toute responsabilité, de tout coût, de toute cause d’action, de tout dommage ou de toute dépense découlant de votre utilisation de ce site ou de votre violation de l’une des dispositions énoncées dans le présent document.</p>
      <h3>Lois applicables</h3>
      <p>Ce document est soumis aux lois applicables en France et vise à se conformer à ses règles et règlements nécessaires. Cela inclut la réglementation à l’échelle de l’UE énoncée dans le RGPD.</p>
      <h3>Divisibilité</h3>
      <p>Si, à tout moment, l’une des dispositions énoncées dans le présent document est jugée incompatible ou invalide en vertu des lois applicables, ces dispositions seront considérées comme nulles et seront retirées du présent document. Toutes les autres dispositions ne seront pas touchées par les lois et le reste du document sera toujours considéré comme valide.</p>
      <h3>Modifications</h3>
      <p>Ces conditions générales peuvent être modifiées de temps à autre afin de maintenir le respect de la loi et de refléter tout changement à la façon dont nous gérons notre site et la façon dont nous nous attendons à ce que les utilisateurs se comportent sur notre site. Nous recommandons à nos utilisateurs de vérifier ces conditions générales de temps à autre pour s’assurer qu’ils sont informés de toute mise à jour. Au besoin, nous informerons les utilisateurs par courriel des changements apportés à ces conditions ou nous afficherons un avis sur notre site.</p>
      <h3>Contact</h3>
      <p>Veuillez communiquer avec nous si vous avez des questions ou des préoccupations. Nos coordonnées sont les suivantes : pothemllc@gmail.com</p>
      <p>Date d'entrée en vigueur : le 5 janvier 2022</p>
    </div>
  </main>
</template>

<script>
export default {
  name: 'MentionsLegales',
};
</script>

<style scoped>

</style>
