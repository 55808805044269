<template>
  <main>
    <img src="@/assets/icones/logo-emancipation-2.svg" alt="image_header" class="icones-header">
    <h1 class="titre-header">PROJET ÉMANCIPATION</h1>
    <router-link to="Information" tag="a"><img src="@/assets/icones/logo-pothem-blanc.svg" alt="POTHEM LLC" class="icone-pothem-header"></router-link>
    <div id="accueil-0">
      <div class="flex">
        <div class="gauche">
          <img src="@/assets/icones/logo-emancipation.svg" alt="Emancipation">
        </div>
        <div class="droite">
          <h2>POTHEM LLC</h2>
          <p>Emancipation, Transmissions, Héritages</p>
          <router-link to="Emancipation" class="savoir-plus">Voir l'abécédaire</router-link>
        </div>
      </div>
      <h2>PROJET ÉMANCIPATION</h2>
      <p>Ce projet de recherche fédère les 5 laboratoires de recherche du pôle Langues Lettres et communication de l’UBFC et rassemble notamment des chercheur·e·s en langues, littératures et civilisations étrangères, en lettres modernes et classiques, en littérature, en arts, en psychologie, en linguistique ou communication.<br>
        Il vise à interroger la notion d’émancipation, concept largement sollicité sans toujours être précisément défini, en utilisant les compétences propres à chaque laboratoire dans une démarche interdisciplinaire.<br>
        Quatre volets ont ainsi été définis :<br>
        - un volet sur le concept d’émancipation lui-même : définitions, contours, domaines d’application<br>
        - un volet sur les archives : valorisation d’archives existantes et constitution d’archives<br>
        - un volet sur la création et le partage (transmission/diffusion/médiation) des savoirs : sollicitation d’outils existants, création d’outils et réflexion sur les méthodologies utilisées<br>
        - un volet sur la recherche/création : sollicitation d’artistes, organisation d’ateliers d’écriture créative, et ouverture sur la société civile.<br>
        Le projet sera lancé le 29 septembre 2021 avec une manifestation scientifique ouverte au public, « Hétérotopies : espaces d’émancipation ? », qui se tiendra à Besançon et sera simultanément retransmise à Dijon et à Montbéliard.</p>
      <img src="@/assets/img/amphi_head-3.png" alt="Amphi">
      <p>Pour le blog …
        Selon certains dictionnaires l’émancipation, c’est  l’action d’affranchir ou de s’affranchir  « d’une entrave, d’un état de dépendance, d’une domination » (Larousse). « d’une autorité, de servitudes ou de préjugés. » (Le Robert).
        Nous accueillons toutes vos contributions sur le thème de l’émancipation : créations personnelles, poèmes ou textes, ou images que vous souhaitez partager avec nous. Si vous choisissez cette dernière solution, merci d’introduire le document par quelques mots expliquant votre choix. Sentez-vous le besoin d’émancipation ou l’avez-vous ressenti à un moment de votre vie ? Diriez-vous qu’aujourd’hui vous vous êtes émancipé.e … de … de quoi au fond ? Quels sont les obstacles à l’émancipation, qu’est-ce qui la favorise ? Qu’est-ce qui vous relie ou relie le texte à l’émancipation ou à votre conception de l’émancipation ?</p>
    </div>
    <div id="accueil-1">
      <div class="flex">
        <div class="gauche">
          <router-link to="Actualites" tag="a"><img src="@/assets/icones/actualites2.svg" alt="actualités"></router-link>
        </div>
        <div class="milieu">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum id faucibus mi. Sed
            orci massa, elementum nec arcu id, venenatis pulvinar nunc. Donec tellus risus,
            consectetur ac ipsum ac, sollicitudin varius felis. Nam in lacinia nisl. Vestibulum
            dictum, est eu ullamcorper vehicula, diam sapien aliquet magna, non</p>
          <router-link to="Actualites" class="savoir-plus">En savoir plus</router-link>
        </div>
        <div class="droite">
          <img src="@/assets/img/illustration1.png" alt="illustration">
        </div>
      </div>
    </div>
    <div id="accueil-2">
      <div class="flex">
        <div class="gauche">
          <img src="@/assets/img/illustration2.png" alt="illustration">
        </div>
        <div class="droite">
          <div class="flex">
            <div class="gauche">
              <router-link to="Conferences" tag="a"><img src="@/assets/icones/conferences2.svg" alt="conférences"></router-link>
            </div>
            <div class="droite">
              <h2>Dernière Conférence</h2>
              <router-link to="Conferences" tag="a"><img src="@/assets/img/exemple-conference.png" alt="conférence"></router-link>
            </div>
          </div>
          <router-link to="Conferences" class="voir-plus">Voir plus</router-link>
        </div>
      </div>
    </div>
    <div id="accueil-3">
      <div class="flex">
        <div class="gauche">
          <router-link to="Emancipation" tag="a"><img src="@/assets/icones/logo-emancipation-2.svg" alt="émancipation"></router-link>
        </div>
        <div class="droite">
          <h2>Projet Émancipation</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum id faucibus mi. Sed
            orci massa, elementum nec arcu id, venenatis pulvinar nunc. Donec tellus risus,
            consectetur ac ipsum ac, sollicitudin varius felis. Nam in lacinia nisl. Vestibulum
            dictum, est eu ullamcorper vehicula, diam sapien aliquet magna, non ultricies enim
            turpis sed odio. Morbi ut nulla pretium, scelerisque nisi eu, molestie tortor. Integer
            ut felis eu dolor mattis aliquam vel ut dolor. Suspendisse at nibh lobortis, tincidunt
            lorem ut, ultricies tellus.</p>
          <router-link to="Emancipation" class="savoir-plus">En savoir plus</router-link>
        </div>
      </div>
    </div>
    <div id="accueil-4">
      <h2>ÉVÈNEMENTS</h2>
      <div class="flex-3">
        <div v-for="evenement in liste_evenements" :key="evenement.id">
          <img :src="evenement.better_featured_image.source_url" alt="Évènement">
          <h3>{{evenement.title.rendered}}</h3>
          <p>{{evenement.content.rendered | liveSubstr(200)}}</p>
          <a onclick="window.open(this.href); return false;" :href="evenement.link" class="savoir-plus">Voir l'évènement</a>
        </div>
      </div>
    </div>
    <div id="accueil-5">
      <div class="flex">
        <div class="gauche">
          <router-link to="Projets" tag="a"><img src="@/assets/icones/projets2.svg" alt="projets"></router-link>
        </div>
        <div class="droite">
          <h2>Dernier Projet</h2>
          <div v-for="posts in liste_projets.slice(0,1)" :key="posts.id">
            <img :src="posts.better_featured_image.source_url" alt="Projet">
            <h3>{{posts.title.rendered}}</h3>
            <p>{{posts.content.rendered | liveSubstr(200)}}</p>
            <a onclick="window.open(this.href); return false;" :href="posts.link" class="voir-plus">Voir le projet</a>
          </div>
          <router-link to="Projets" class="savoir-plus">Voir tous les projets</router-link>
        </div>
      </div>
    </div>
    <div id="accueil-6">
      <div class="flex">
        <div class="gauche">
          <router-link to="Contact" tag="a"><img src="@/assets/icones/contact2.svg" alt="contact"></router-link>
        </div>
        <div class="droite">
          <h2>POTHEM LLC - Bourgogne-Franche-Comté</h2>
          <p>Lorem ipsum<br>
            <strong>Tél.</strong>Lorem ipsum<br>
            <strong>Mail :</strong>pothemllc@gmail.com<br>
          </p>
          <router-link to="Contact" class="voir-plus">Nous écrire</router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
/* eslint-disable */
import param from '@/param/param.js';

export default {
  name: 'Accueil',
  data() {
    return {
      liste_projets: [],
      liste_evenements: [],
    };
  },
  filters: {
    liveSubstr: function (string, nb) {
      return string.substring(4,nb) + '...';
    },
    liveDate: function (string, nb) {
      return string.substring(0,nb);
    }
  },
  created() {
    axios.get(param.host + 'posts').then((response) => {
      console.log("Reponse", response);
      this.liste_projets = response.data;
    })
      .catch((error) => console.log(error));
    axios.get(param.host + 'evenement').then((response) => {
      console.log("Reponse", response);
      this.liste_evenements = response.data;
    })
  },
}
</script>

<style scoped lang="less">
@import "/src/assets/less/styles.less";

</style>
